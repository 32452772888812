<template>
  <v-container
    fluid
    fill-height
    class="align-start justify-start"
  >
    <v-card class="members-table-wrapper">
      <v-data-table
        :headers="tableHeaders"
        :items="company.members"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-row
            class="mt-1 align-start no-gutters"
          >
            <v-col cols="10">
              <v-card-title>
                Members
              </v-card-title>
            </v-col>
            <v-col cols="2">
              <v-btn
                color="primary"
                rounded
                outlined
                small
                class="mr-2 mt-2"
                @click="deleteGroupDialog"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-account-multiple-minus
                </v-icon>
                delete group
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.main="{ item }">
          <v-chip
            v-if="item.main"
            color="primary"
          >
            {{ $t('members|account_main') }}
          </v-chip>
          <v-chip
            v-else
            color="secondary"
          >
            {{ $t('members|account_sub') }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="!item.main"
            @click="openDeleteDialog(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
// import moment from 'moment'
import { mapState } from 'vuex'
// const createdAtFormat = date => moment(date).format('DD/MM/YYYY')

export default {
  components: {
  },
  props: {
    group: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      accounts: [],
      loading: false,
      error: null,
      dialog: false,
      search: ''
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    tableHeaders () {
      const headers = [
        {
          text: this.$t('members|members'),
          align: 'start',
          value: 'email'
        },
        {
          text: this.$t('members|account_type'),
          align: 'start',
          value: 'main'
        },
        {
          text: this.$t('common|date_created'),
          align: 'start',
          value: 'createdAt'
        }
      ]
      headers.push({ text: this.$t('actions|actions'), value: 'actions', sortable: false, align: 'end' })
      return headers
    }
  },
  methods: {
    async openDeleteDialog (account) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('members|warning')
      })
      if (res) {
        try {
          await this.$axios.post(`/deleteSubAccount/${account._id}`)
          const index = this.accounts.findIndex((acc) => acc._id === account._id)
          this.accounts.splice(index, 1)
        } catch (e) {
          console.error(e, 'Error')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .members-table-wrapper {
    width: 100%;
  }
</style>
